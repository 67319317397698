import { FaEnvelope } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { pages } from "./routes";

const Footer = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return pages.some(
    (page) => page.path === pathname || page.path + "/" === pathname
  ) ? (
    <footer className="py-5 mt-auto bg-neutral-100 border-t border-neutral-300">
      <div className="max-w-screen-2xl mx-auto px-2">
        <div className="flex md:flex-row flex-col justify-between w-full items-center">
          <a href="/" className="w-64">
            <img src="/img/URGENTEL-FF-01.png" className="w-full" alt="Logo" />
          </a>
          <div className="flex items-center w-fit gap-4">
            <FaEnvelope className="w-6 h-auto" />
            <a
              href="mailto:steven@urgentel.com"
              className="text-lg hover:text-red-800"
            >
              steven@urgentel.com
            </a>
          </div>
        </div>
        <div className="w-full h-px bg-neutral-300 my-8"></div>
        <div className="flex md:flex-row flex-col items-center justify-center w-full">
          <a
            className={classNames(
              pathname === "/"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4 md:border-r border-white"
            )}
            href="/"
          >
            Home
          </a>
          <a
            className={classNames(
              pathname === "/about-us"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4 md:border-r border-white"
            )}
            href="/about-us"
          >
            About Us
          </a>
          <a
            className={classNames(
              pathname === "/urgent-services"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4 md:border-r border-white"
            )}
            href="/urgent-services"
          >
            Urgent Services
          </a>
          <a
            className={classNames(
              pathname === "/quick-services"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4 md:border-r border-white"
            )}
            href="/quick-services"
          >
            Quick Services
          </a>
          <a
            className={classNames(
              pathname === "/health-partners"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4 md:border-r border-white"
            )}
            href="/health-partners"
          >
            Health Partners
          </a>
          <a
            className={classNames(
              pathname === "/contact-us"
                ? "text-sky-700"
                : "text-black hover:text-sky-700",
              "text-lg px-4"
            )}
            href="/contact-us"
          >
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  ) : null;
};

export default Footer;
