import { Fragment, useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { Sidenav, initTE } from "tw-elements";
import useWindowSize from "../hooks/useWindowSize";
import { pages, routes } from "./routes";

const Navbar = () => {
  const size = useWindowSize();
  const location = useLocation();
  const pathname = location.pathname;
  const [expanded, setExpanded] = useState(false);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  useEffect(() => {
    initTE({ Sidenav });
  }, []);

  return (
    <Fragment>
      <nav
        className={classNames(
          pages.some(
            (page) => page.path === pathname || page.path + "/" === pathname
          )
            ? ""
            : "hidden",
          "bg-white w-full py-1.5 px-2 flex justify-center items-center"
        )}
      >
        <div className="max-w-screen-2xl flex justify-between items-center mx-auto w-full">
          <a href="/" className="w-64">
            <img src="/img/URGENTEL-FF-01.png" className="w-full" alt="Logo" />
          </a>
          <div className="flex items-center justify-end lg:gap-x-12 gap-x-2 w-fit">
            <a
              href="/contact-us"
              className="btn-primary text-white bg-red-800 text-lg heading hover:bg-black md:block hidden"
            >
              CALL FOR IMMEDIATE ASSISTANCE
            </a>
            <button
              className="text-3xl"
              aria-controls="#sidenav-1"
              aria-haspopup="true"
              onClick={() => setExpanded(true)}
            >
              <GiHamburgerMenu />
            </button>
          </div>
        </div>
      </nav>
      <nav
        className={classNames(
          pages.some(
            (page) => page.path === pathname || page.path + "/" === pathname
          )
            ? "md:hidden flex"
            : "hidden",
          "bg-white w-full py-1.5 px-2 justify-center items-center"
        )}
      >
        <a
          href="/contact-us"
          className="btn-primary text-white bg-red-800 heading hover:bg-black w-full text-center"
        >
          CALL FOR IMMEDIATE ASSISTANCE
        </a>
      </nav>
      {routes()}
      <nav
        id="sidenav-1"
        className={classNames(
          expanded ? "!-translate-x-0" : "!translate-x-full",
          "fixed flex flex-col items-end right-0 top-0 z-[1035] h-screen md:!w-72 !w-full overflow-x-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] dark:bg-zinc-800"
        )}
        data-te-sidenav-init
        data-te-sidenav-hidden="true"
        data-te-sidenav-right="true"
      >
        <button className="p-4" onClick={() => setExpanded(false)}>
          <FaXmark className="w-6 h-6" />
        </button>
        <ul className="relative m-0 list-none px-[0.2rem] w-full">
          <li className="relative">
            <a
              className={classNames(
                pathname === "/" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/"
            >
              Home
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/about-us" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/about-us"
            >
              About Us
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/urgent-services"
                  ? "text-sky-700"
                  : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/urgent-services"
            >
              Urgent Services
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/quick-services"
                  ? "text-sky-700"
                  : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/quick-services"
            >
              Quick Services
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/patient-portal"
                  ? "text-sky-700"
                  : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/patient-portal"
            >
              Patient Portal
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/provider-services"
                  ? "text-sky-700"
                  : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/provider-services"
            >
              Provider Services
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/urgentel-app" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/urgentel-app"
            >
              Urgentel App
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/health-partners"
                  ? "text-sky-700"
                  : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/health-partners"
            >
              Health Partners
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/contact-us" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/contact-us"
            >
              Contact Us
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/insurance" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/insurance"
            >
              Insurance
            </a>
          </li>
          <li className="relative">
            <a
              className={classNames(
                pathname === "/our-team" ? "text-sky-700" : "text-gray-600",
                "flex h-12 cursor-pointer items-center justify-center truncate rounded-[5px] px-6 py-2.5 text-lg outline-none transition duration-300 ease-linear hover:text-sky-700 hover:outline-none focus:bg-slate-50 focus:text-sky-700 focus:outline-none active:bg-slate-50 active:text-sky-700 active:outline-none data-[te-sidenav-state-active]:text-sky-700 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
              )}
              href="/our-team"
            >
              Our Team
            </a>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default Navbar;
