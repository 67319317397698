import React, { Suspense } from "react";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Loading from "./Loading";

const Home = React.lazy(() => import("../pages/Home"));
const Partners = React.lazy(() => import("../pages/Partners"));
const Insurance = React.lazy(() => import("../pages/Insurance"));
const MobileApp = React.lazy(() => import("../pages/MobileApp"));
const PatientPortal = React.lazy(() => import("../pages/PatientPortal"));
const ProviderServices = React.lazy(() => import("../pages/ProviderServices"));
const About = React.lazy(() => import("../pages/About"));
const QuickServices = React.lazy(() => import("../pages/QuickServices"));
const UrgentServices = React.lazy(() => import("../pages/UrgentServices"));
const Team = React.lazy(() => import("../pages/Team"));
const Contact = React.lazy(() => import("../pages/Contact"));
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));

export const pages = [
  { path: "/", component: <Home /> },
  { path: "/urgent-services", component: <UrgentServices /> },
  { path: "/quick-services", component: <QuickServices /> },
  { path: "/provider-services", component: <ProviderServices /> },
  { path: "/patient-portal", component: <PatientPortal /> },
  { path: "/urgentel-app", component: <MobileApp /> },
  { path: "/health-partners", component: <Partners /> },
  { path: "/insurance", component: <Insurance /> },
  { path: "/about-us", component: <About /> },
  { path: "/contact-us", component: <Contact /> },
  { path: "/our-team", component: <Team /> },
  { path: "*", component: <PageNotFound /> },
];

//Replace fallback with real loading page
export const routes = () => {
  return (
    <Suspense
      fallback={
        <h3 style={{ textAlign: "center", padding: "50px" }}>Loading...</h3>
      }
    >
      <Routes>
        {pages.map((page) => {
          return <Route path={page.path} exact element={page.component} />;
        })}
      </Routes>
    </Suspense>
  );
};
